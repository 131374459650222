var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"head"},[(!_vm.$route.query.from)?_c('Header',{attrs:{"callBack":""},on:{"click":_vm.goBack},scopedSlots:_vm._u([(_vm.token)?{key:"right",fn:function(){return [_c('span',{staticStyle:{"color":"#0754d3"},on:{"click":_vm.getUserTeamCardgameStakLog}},[_vm._v("投票记录")])]},proxy:true}:null],null,true)},[[_vm._v("选择心仪的球队")]],2):_vm._e(),(_vm.gameList.length)?_c('div',{staticClass:"swiperBox"},[_c('swiper',{ref:"swiper",staticClass:"swiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.swiperList),function(item){return _c('swiper-slide',{key:item.id,staticClass:"slideItem",class:item.id === _vm.swiperActive ? 'active' : null},[_c('span',{on:{"click":function($event){return _vm.changeSwiperId(item)}}},[_vm._v(_vm._s(item.name))])])}),_c('div',{staticClass:"swiper-button swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"})],2)],1):_vm._e()],1),_c('div',{staticClass:"main",class:!_vm.gameList.length ? 'noData' : '',style:({ top: !_vm.$route.query.from ? '100px' : '50px' })},[_c('div',{staticClass:"list"},[_vm._l((_vm.gameList),function(item){return _c('div',{key:item.id,staticClass:"listItem",class:item.guessing === 3
            ? 'listItem1'
            : item.guessing === 2
            ? 'listItem2'
            : null},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"headerLeft"},[_vm._v(_vm._s(_vm.guessingMap[item.guessing]))]),_c('div',{staticClass:"headerRight"},[_vm._v(_vm._s(item.game_time))])]),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"middleChild"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(item.home_team)+" "),_c('i',{staticClass:"zhu"},[_vm._v("主")])]),_c('div',{staticClass:"subTitle"},[_vm._v("热度: "+_vm._s(item.homeHotNum))])]),_c('div',{staticClass:"middleChild"},[_c('div',{staticClass:"title"},[_c('i',[_vm._v("客")]),_vm._v(_vm._s(item.guest_team))]),_c('div',{staticClass:"subTitle"},[_vm._v("热度: "+_vm._s(item.guestHotNum))])])]),(item.guessing === 1)?_c('div',{staticClass:"fotter"},[(item.joinHome == 1)?_c('div',{staticClass:"btn active"},[_vm._v("已投票")]):_c('div',{staticClass:"btn",class:item.is_show === 1
                ? 'des'
                : item.joinHome == 1 || item.joinGuest == 1
                ? 'active'
                : null,on:{"click":function($event){return _vm.showCompetition(item, item.home_team_id)}}},[_vm._v(" 投票 ")]),(item.joinGuest == 1)?_c('div',{staticClass:"btn active"},[_vm._v("已投票")]):_c('div',{staticClass:"btn",class:item.is_show === 1
                ? 'des'
                : item.joinHome == 1 || item.joinGuest == 1
                ? 'active'
                : null,on:{"click":function($event){return _vm.showCompetition(item, item.guest_team_id)}}},[_vm._v(" 投票 ")])]):_vm._e(),(item.guessing === 3)?_c('div',{staticClass:"fotter"},[(item.goodsNumber)?_c('div',{staticClass:"jiaoziNum"},[_c('div',{staticClass:"jiaoziNumLeft"},[_c('span',{staticClass:"jiaoziTitle"},[_vm._v("使用球队：")]),_c('span',{staticClass:"jiaoziContent"},[_vm._v(_vm._s(item.goodsNumber))])]),_c('div',{staticClass:"jiaoziNumRight",class:item.status == 0
                  ? 'yellow'
                  : item.status == 1
                  ? 'green'
                  : item.status == 2
                  ? 'grey'
                  : 'red'},[_vm._v(" "+_vm._s(_vm.statusMap[item.status])+" ")])]):_vm._e()]):_vm._e(),(item.guessing === 3)?_c('div',{staticClass:"center"},[_c('div',{staticClass:"vs",staticStyle:{"color":"#fff209"}},[_vm._v(_vm._s(item.score))])]):_c('div',{staticClass:"center"},[_c('div',{staticClass:"vs"},[_vm._v("vs")])])])}),(!_vm.gameList.length)?_c('div',{staticClass:"def def1"},[_c('img',{attrs:{"src":require("../../../static/image/emptyBox.png"),"alt":""}}),_c('p',[_vm._v("更多赛事，敬请期待")])]):_vm._e()],2)]),_c('van-popup',{staticClass:"popUp",model:{value:(_vm.record),callback:function ($$v) {_vm.record=$$v},expression:"record"}},[_c('div',{staticClass:"header"},[_vm._v("投票记录")]),_c('div',{staticClass:"tab tab1"},[_c('div',{staticClass:"tabItem"},[_vm._v("比赛球队")]),_c('div',{staticClass:"tabItem"},[_vm._v("等级")]),_c('div',{staticClass:"tabItem"},[_vm._v("状态")])]),_c('div',{staticClass:"content"},[_vm._l((_vm.logList),function(item){return _c('div',{key:item.id,staticClass:"tab"},[_c('div',{staticClass:"tabItem"},[_c('span',{class:item.team_id === item.game.home_team_id ? 'active' : null},[_vm._v(_vm._s(item.game.home_team))]),_vm._v(" vs "),_c('span',{class:item.team_id === item.game.guest_team_id ? 'active' : null},[_vm._v(_vm._s(item.game.guest_team))])]),_c('div',{staticClass:"tabItem"},[_vm._v(_vm._s(item.star))]),_c('div',{staticClass:"tabItem",class:item.status == 0
              ? 'yellow'
              : item.status == 1
              ? 'green'
              : item.status == 2
              ? 'grey'
              : 'red'},[_vm._v(" "+_vm._s(item.statusName)+" ")])])}),(!_vm.logList.length)?_c('div',{staticClass:"def"},[_c('img',{attrs:{"src":require("../../../static/image/emptyBox.png"),"alt":""}}),_c('p',[_vm._v("暂无数据")])]):_vm._e()],2),_c('div',{staticClass:"close",on:{"click":function($event){_vm.record = false}}})]),_c('van-popup',{staticClass:"popUp1",model:{value:(_vm.showPopUp),callback:function ($$v) {_vm.showPopUp=$$v},expression:"showPopUp"}},[_c('div',{staticClass:"header"},[_vm._v("申购规则")]),_c('div',{staticClass:"content"}),_c('div',{staticClass:"bottom",on:{"click":function($event){_vm.showPopUp = false}}},[_vm._v("我知道了")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }