<template>
  <div>
    <div class="head">
      <Header callBack @click="goBack" v-if="!$route.query.from">
        <template>选择心仪的球队</template>
        <template v-slot:right v-if="token"
          ><span @click="getUserTeamCardgameStakLog" style="color: #0754d3"
            >投票记录</span
          ></template
        >
      </Header>

      <div class="swiperBox" v-if="gameList.length">
        <swiper :options="swiperOption" class="swiper" ref="swiper">
          <swiper-slide
            v-for="item in swiperList"
            :key="item.id"
            class="slideItem"
            :class="item.id === swiperActive ? 'active' : null"
          >
            <span @click="changeSwiperId(item)">{{ item.name }}</span>
          </swiper-slide>
          <div
            class="swiper-button swiper-button-prev"
            slot="button-prev"
          ></div>
          <div
            class="swiper-button swiper-button-next"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
    <!-- main -->
    <div
      class="main"
      :class="!gameList.length ? 'noData' : ''"
      :style="{ top: !$route.query.from ? '100px' : '50px' }"
    >
      <div class="list">
        <div
          class="listItem"
          :class="
            item.guessing === 3
              ? 'listItem1'
              : item.guessing === 2
              ? 'listItem2'
              : null
          "
          v-for="item in gameList"
          :key="item.id"
        >
          <div class="header">
            <div class="headerLeft">{{ guessingMap[item.guessing] }}</div>
            <div class="headerRight">{{ item.game_time }}</div>
          </div>
          <div class="middle">
            <div class="middleChild">
              <div class="title">
                {{ item.home_team }} <i class="zhu">主</i>
              </div>
              <div class="subTitle">热度: {{ item.homeHotNum }}</div>
            </div>
            <div class="middleChild">
              <div class="title"><i>客</i>{{ item.guest_team }}</div>
              <div class="subTitle">热度: {{ item.guestHotNum }}</div>
            </div>
          </div>
          <div class="fotter" v-if="item.guessing === 1">
            <div v-if="item.joinHome == 1" class="btn active">已投票</div>
            <div
              v-else
              class="btn"
              :class="
                item.is_show === 1
                  ? 'des'
                  : item.joinHome == 1 || item.joinGuest == 1
                  ? 'active'
                  : null
              "
              @click="showCompetition(item, item.home_team_id)"
            >
              投票
            </div>
            <div class="btn active" v-if="item.joinGuest == 1">已投票</div>
            <div
              v-else
              class="btn"
              :class="
                item.is_show === 1
                  ? 'des'
                  : item.joinHome == 1 || item.joinGuest == 1
                  ? 'active'
                  : null
              "
              @click="showCompetition(item, item.guest_team_id)"
            >
              投票
            </div>
          </div>
          <div class="fotter" v-if="item.guessing === 3">
            <div class="jiaoziNum" v-if="item.goodsNumber">
              <div class="jiaoziNumLeft">
                <span class="jiaoziTitle">使用球队：</span
                ><span class="jiaoziContent">{{ item.goodsNumber }}</span>
              </div>
              <div
                class="jiaoziNumRight"
                :class="
                  item.status == 0
                    ? 'yellow'
                    : item.status == 1
                    ? 'green'
                    : item.status == 2
                    ? 'grey'
                    : 'red'
                "
              >
                {{ statusMap[item.status] }}
              </div>
            </div>
          </div>
          <div class="center" v-if="item.guessing === 3">
            <div class="vs" style="color: #fff209">{{ item.score }}</div>
            <!-- <div class="let">让 {{ item.concede_points }}</div> -->
          </div>
          <div class="center" v-else>
            <div class="vs">vs</div>
            <!-- <div class="let">让 {{ item.concede_points }}</div> -->
          </div>
        </div>
        <div v-if="!gameList.length" class="def def1">
          <img src="../../../static/image/emptyBox.png" alt="" />
          <p>更多赛事，敬请期待</p>
        </div>
      </div>
    </div>

    <!-- 投票记录 -->
    <van-popup v-model:show="record" class="popUp">
      <div class="header">投票记录</div>
      <div class="tab tab1">
        <div class="tabItem">比赛球队</div>
        <div class="tabItem">等级</div>
        <div class="tabItem">状态</div>
      </div>
      <div class="content">
        <div class="tab" v-for="item in logList" :key="item.id">
          <div class="tabItem">
            <span
              :class="item.team_id === item.game.home_team_id ? 'active' : null"
              >{{ item.game.home_team }}</span
            >
            vs
            <span
              :class="
                item.team_id === item.game.guest_team_id ? 'active' : null
              "
              >{{ item.game.guest_team }}</span
            >
          </div>
          <div class="tabItem">{{ item.star }}</div>
          <div
            class="tabItem"
            :class="
              item.status == 0
                ? 'yellow'
                : item.status == 1
                ? 'green'
                : item.status == 2
                ? 'grey'
                : 'red'
            "
          >
            {{ item.statusName }}
          </div>
        </div>
        <div v-if="!logList.length" class="def">
          <img src="../../../static/image/emptyBox.png" alt="" />
          <p>暂无数据</p>
        </div>
      </div>
      <div class="close" @click="record = false"></div>
    </van-popup>

    <van-popup v-model:show="showPopUp" class="popUp1">
      <div class="header">申购规则</div>
      <div class="content"></div>
      <div class="bottom" @click="showPopUp = false">我知道了</div>
    </van-popup>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import { addBridgeForAndroidWebView } from 'sdbridge-android';
import moment from 'moment';
export default {
  name: 'lickTeam',
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      day: moment().format('YYYY-MM-DD'),
      timer: null,
      showPopUp: false,
      record: false,
      logQuery: {
        pagesize: 1000,
        pageindex: 1,
      },
      swiperOption: {
        allowTouchMove: true,
        slidesPerView: 4,
        spaceBetween: 10,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      token: null,
      isAndroid:
        navigator.userAgent.indexOf('Android') > -1 ||
        navigator.userAgent.indexOf('Adr') > -1,
      isiOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
      bridge: null,
      swiperList: [
        { id: 0, name: '11-21' },
        { id: 1, name: '11-22' },
        { id: 2, name: '11-23' },
        { id: 3, name: '11-24' },
        { id: 4, name: '11-25' },
        { id: 5, name: '11-26' },
        { id: 6, name: '11-27' },
        { id: 7, name: '11-28' },
        { id: 8, name: '11-29' },
        { id: 9, name: '11-30' },
        { id: 10, name: '12-01' },
        { id: 11, name: '12-02' },
        { id: 12, name: '12-03' },
        { id: 13, name: '12-04' },
        { id: 14, name: '12-05' },
        { id: 15, name: '12-06' },
        { id: 16, name: '12-07' },
        { id: 17, name: '12-08' },
        { id: 18, name: '12-09' },
        { id: 19, name: '12-10' },
        { id: 20, name: '12-11' },
        { id: 21, name: '12-12' },
        { id: 22, name: '12-13' },
        { id: 23, name: '12-14' },
        { id: 24, name: '12-15' },
        { id: 25, name: '12-16' },
        { id: 26, name: '12-17' },
        { id: 27, name: '12-18' },
      ],
      swiperActive: 0,
      gameList: [],
      logList: [],
      guessingMap: {
        1: '投票中',
        2: '投票已结束',
        3: '比赛已结束',
      },
      statusMap: {
        0: '冻结中',
        1: '已升级',
        2: '已销毁',
        3: '已退回',
      },
    };
  },
  mounted() {
    const data = moment().format('MM-DD');
    this.swiperActive =
      (this.swiperList.find((e) => e.name === data) || {}).id || 0;

    this.$refs.swiper.swiper.slideTo(
      this.swiperList.findIndex((e) => e.name === data),
    );

    this.token = this.$route.query.token;
    this.setTime();
    let bridge = window.WebViewJavascriptBridge;
    if (!bridge) {
      console.log('TypeScript正在尝试挂载');
      addBridgeForAndroidWebView();
      //@ts-ignore
      bridge = window.WebViewJavascriptBridge;
      if (bridge) {
        console.log(
          'window.WebViewJavascriptBridge, 已经被TypeScript挂载成功!',
        );
        this.bridge = bridge;
      }
    } else {
      this.bridge = bridge;
      console.log('window.WebViewJavascriptBridge 挂载成功！！');
    }
  },
  activated() {
    this.getTeamCardgameStake(
      this.swiperList.find((e) => e.id === this.swiperActive).name,
    );
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    setTime() {
      this.timer = setInterval(() => {
        try {
          this.gameList.forEach((e) => {
            const time = moment(`${this.day} ${e.game_time}`).unix();
            if (time === moment().unix()) {
              const item = this.swiperList.find(
                (e) => e.id === this.swiperActive,
              );
              this.getTeamCardgameStake(item.name);
            }
          });
        } catch (error) {
          //
        }
      }, 1000);
    },
    getTeamCardgameStake(day) {
      const query = {
        day,
      };
      if (this.token) {
        query.token = this.token;
      }
      this.$api.getTeamCardgameStake(query).then((e) => {
        if (e.code === 0) {
          this.gameList = e.data;
        } else {
          this.$toast(e.msg);
        }
      });
    },
    goBack() {
      if (this.$route.query.platform) {
        if (this.isAndroid) {
          // eslint-disable-next-line no-undef
          control.jumptoapp(JSON.stringify({ key: -2 }));
        } else if (this.isiOS) {
          this.bridge.callHandler(
            'jumptoapp',
            { key: -2 },
            function (response) {
              console.log(JSON.stringify(response));
            },
          );
        }
      } else {
        this.$router.back();
      }
    },
    showCompetition(item, teamId) {
      if (item.is_show == 1) return false;
      if (item.joinHome == 1 || item.joinGuest == 1) {
        return false;
      }
      if (!this.token) {
        // 如果是app 无token 跳转至登录页面
        if (this.$route.query.platform) {
          if (this.isAndroid) {
            // eslint-disable-next-line no-undef
            control.jumptoapp(JSON.stringify({ key: -1 }));
          } else if (this.isiOS) {
            this.bridge.callHandler(
              'jumptoapp',
              { key: -1 },
              function (response) {
                console.log(JSON.stringify(response));
              },
            );
          }
          return;
        }
        return this.$router.push('/login');
      }
      this.$router.push(
        `/useTeam?id=${item.id}&teamId=${teamId}&token=${this.token}`,
      );
    },
    changeSwiperId(item) {
      this.swiperActive = item.id;
      this.getTeamCardgameStake(item.name);
    },
    // 获取记录
    getUserTeamCardgameStakLog() {
      this.record = true;
      const query = {
        ...this.logQuery,
        token: this.token,
      };
      this.$api.getUserTeamCardgameStakLog(query).then((e) => {
        if (e.code === 0) {
          this.logList = e.data;
        } else {
          this.$toast(e.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.popUp {
  width: 80%;
  padding: 18px;
  box-sizing: border-box;
  border-radius: 5px;
  height: 280px;
  display: flex;
  flex-direction: column;
  overflow: visible;
  .header {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    margin-bottom: 16px;
  }

  .tab {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    .tabItem {
      color: #333333;
      flex: 1;
      font-size: 12px;
      text-align: center;
      &:first-child {
        flex: 2;
      }
      .active {
        color: #2a922a;
      }
      &.yellow {
        color: #e78e10;
      }
      &.green {
        color: #009944;
      }
      &.grey {
        color: #999999;
      }
      &.red {
        color: #e60012;
      }
    }
    &.tab1 {
      .tabItem {
        font-size: 14px;
      }
    }
  }
  .content {
    min-height: 1px;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 -18px;
    padding: 0 18px;
  }
  .close {
    width: 27px;
    height: 27px;
    border: 1px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 40px;
    transform: rotate(45deg) translateX(-50%);
    &::before {
      display: block;
      content: '';
      width: 80%;
      height: 2px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    &::after {
      display: block;
      content: '';
      height: 80%;
      width: 2px;
      background: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      visibility: visible;
    }
  }
}

.swiperBox {
  position: relative;
  padding: 0 35px;
  margin-top: 5px;
  .swiper {
    position: unset;
    .slideItem {
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      padding: 5px 0;
      color: #333333;
      span {
        border-bottom: 3px solid transparent;
      }
      &.active {
        color: #2a922a;
        span {
          border-bottom: 3px solid #2a922a;
        }
      }
    }
  }
  .swiper-button {
    width: 10px;
    height: 10px;
    background-size: auto;
    margin: 0;
    transform: translateY(-50%);
    background: none;
    &.swiper-button-prev {
      left: 25px;
      border-top: 2.5px solid #238327;
      border-left: 2.5px solid #238327;
      transform: rotate(-45deg) translateY(-50%);
      &.swiper-button-disabled {
        opacity: 1;
        border-top: 2.5px solid #999999;
        border-left: 2.5px solid #999999;
      }
    }
    &.swiper-button-next {
      right: 25px;
      border-top: 2.5px solid #238327;
      border-right: 2.5px solid #238327;
      transform: rotate(45deg) translateY(-50%);
      &.swiper-button-disabled {
        opacity: 1;
        border-top: 2.5px solid #999999;
        border-right: 2.5px solid #999999;
      }
    }
  }
}

.main {
  padding-top: 15px;
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(../../assets/images/teamBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  &.noData {
    background: none;
    padding-top: 0;
  }
  .list {
    height: 100%;
    padding: 0 0 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    .jiaozi {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      .jiaoziLeft {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
      }
      .jiaoziRight {
        img {
          width: 17px;
        }
      }
    }
    .listItem {
      background: url(../../assets/images/jcbg1.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      width: 361px;
      height: 200px;
      margin: 0 auto;
      padding: 15px 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .headerLeft {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #fff31d;
        }
        .headerRight {
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #51a452;
        }
      }
      .middle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .middleChild {
          text-align: center;
          width: 140px;
          .title {
            font-size: 19px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
              font-size: 12px;
              border: 1px solid #fff;
              border-radius: 50%;
              width: 20px;
              height: 20px;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              transform: scale(0.75);
              &.zhu {
                border: 1px solid #fef883;
                color: #fef883;
              }
            }
          }
          .subTitle {
            font-size: 11px;
            font-family: PingFang SC;
            font-weight: 400;
            margin-top: 15px;
            color: #d9ffd9;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .fotter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        padding: 0 10px;
        box-sizing: border-box;
        .btn {
          width: 80px;
          height: 33px;
          border: 1px solid #00561f;
          background: linear-gradient(0deg, #fff100 0%, #fff888 100%);
          border-radius: 5px;
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #135f20;
          display: flex;
          justify-content: center;
          align-items: center;
          box-shadow: 3px 3px 0 #00561f;
          &.active {
            background: #bfbfbf;
            color: #fff;
          }
          &.des {
            background: #bfbfbf;
            color: #fff;
          }
        }
      }
      .center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        margin-top: -6px;
        text-align: center;
        .vs {
          font-size: 35px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          height: 35px;
          line-height: 35px;
        }
        .let {
          font-size: 11px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #94ff96;
        }
      }
      &.listItem1 {
        background: url(../../assets/images/jcbg2.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        height: 175px;
        .fotter {
          margin-bottom: 8px;
          .jiaoziNum {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .jiaoziNumLeft {
              font-size: 12px;
              flex: 1;
              display: flex;
              align-items: flex-start;
              .jiaoziTitle {
                white-space: nowrap;
              }
              .jiaoziContent {
              }
            }
            .jiaoziNumRight {
              font-size: 12px;
              white-space: nowrap;
              margin-left: 10px;
              &.yellow {
                color: #e78e10;
              }
              &.green {
                color: #009944;
              }
              &.grey {
                color: #999999;
              }
              &.red {
                color: #e60012;
              }
            }
          }
        }
      }
      &.listItem2 {
        background: url(../../assets/images/jcbg3.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top center;
        height: 147px;
        .middle {
          margin-bottom: 15px;
        }
      }
    }
  }
}
.popUp1 {
  width: 80%;
  border-radius: 4px;
  .header {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000000;
    text-align: center;
    padding: 20px 0 15px;
  }
  .content {
    margin: 15px 0;
    overflow: auto;
    margin: 0.4rem 0;
    max-height: 60vh;
    min-height: 20vh;
    padding: 0 20px;
    font-size: 16px;
  }
  .bottom {
    font-size: 17px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #0754d3;
    text-align: center;
    padding: 15px;
    border-top: 1px solid #ececec;
  }
}
.head {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
.def {
  text-align: center;
  margin: 10px auto 0;
  width: 100%;
  img {
    width: 30%;
    margin-right: -20px;
  }
  p {
    font-size: 14px;
    color: #aaa;
  }
  &.def1 {
    margin-top: 100px;
  }
}
</style>
